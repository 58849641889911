export { default as Switch } from "./Switch.vue";

import { cva } from "class-variance-authority";

export const switchVariants = cva(
  "focus-visible:ring-ring focus-visible:ring-offset-background peer inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      variant: {
        default:
          "data-[state=unchecked]:bg-input data-[state=checked]:bg-primary",
        background:
          "data-[state=unchecked]:bg-background data-[state=checked]:bg-primary",
      },
      size: {
        default: "h-[24px] w-[44px]",
        sm: "h-[20px] w-[36px]",
        lg: "h-[28px] w-[48px]",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export const switchThumbVariants = cva(
  "bg-foreground pointer-events-none block rounded-full shadow-lg ring-0 transition-transform data-[state=unchecked]:translate-x-0",
  {
    variants: {
      size: {
        default: "h-5 w-5 data-[state=checked]:translate-x-5",
        sm: "h-4 w-4 data-[state=checked]:translate-x-4",
        lg: "h-6 w-6 data-[state=checked]:translate-x-5",
      },
    },
    defaultVariants: {
      size: "default",
    },
  },
);
