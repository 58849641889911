<script setup lang="ts">
import { switchVariants, switchThumbVariants } from ".";
import {
  SwitchRoot,
  type SwitchRootEmits,
  type SwitchRootProps,
  SwitchThumb,
} from "radix-vue";
import { cn } from "@/lib/utils";

const props = defineProps<
  SwitchRootProps & {
    class?: string;
    size?: NonNullable<Parameters<typeof switchVariants>[0]>["size"];
    variant?: NonNullable<Parameters<typeof switchVariants>[0]>["variant"];
  }
>();

const emits = defineEmits<SwitchRootEmits>();
</script>

<template>
  <SwitchRoot
    v-bind="props"
    :class="cn(switchVariants({ size, variant }), $attrs.class ?? '')"
    @update:checked="emits('update:checked', $event)"
  >
    <SwitchThumb
      :class="cn(switchThumbVariants({ size }), $attrs.class ?? '')"
    />
  </SwitchRoot>
</template>
